@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaStdBook.otf') format('truetype');
}

body {
  height: 100vh;
  font-family: "Futura";
  margin: 0;
}

input[type=text] {
  border-radius: 10px;
  border: 1px solid gainsboro;
  padding: 5px 15px 5px 15px;
  color: gray;
}

input[type=date] {
  border-radius: 10px;
  border: 1px solid gainsboro;
  padding: 5px 15px 5px 15px;
  color: gray;
}

select {
  border-radius: 10px;
  border: 1px solid gainsboro;
  padding: 5px 15px 5px 15px;
  color: gray;
}

input[type=password] {
  border-radius: 10px;
  border: 1px solid gainsboro;
  padding: 5px 15px 5px 15px;
  color: gray;
}

button {
  background-color: cornflowerblue;
  color: white !important;
  border-radius: 20px;
  height: 28px;
  width: 9rem;
}

button:hover {
  background-color: dodgerblue;
}

.pro-sidebar>.pro-sidebar-inner {
  background-color: #296fa7 !important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: #296fa7 !important;
  color: white !important;
}

.dialog-overlay {
  position: absolute;
  /* Stay in place */
  z-index: 998;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/ opacity */
}

.dialog-content {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  @apply px-1 disable-scrollbars;
}

.disable-scrollbars {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 16px;
  padding: 20px 30px;
  min-width: 500px;
  min-height: 100px;
  max-height: 90vh;
  height: min-content;
  z-index: 999;
}

.button-primary {
  @apply w-max py-2 px-3 rounded-md font-bold text-white;
  background-color: #296fa7;
  width: 100px;
  height: 37px;
}

.button-secondary {
  @apply w-max py-2 px-3 rounded-md font-bold text-white;
  background-color: #296fa7;
  height: 37px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  background-color: #296fa7 !important;
}

.div_login {
  font-family: "Futura" !important;
  font-size: 10px !important;
}

.div_login input {
  font-size: 10px !important;
  padding: 1px 8px 1px 8px !important;
  height: 20px !important;
}

.div_login button {
  height: 16px;
  font-size: 10px !important;
}

.react-confirm-alert-body h1 {
  font-size: 18px;
  font-weight: bold;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(15, 15, 15, 0.7);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: "Futura";
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body>h1 {
  margin-top: 0;
}

.react-confirm-alert-body>h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group>button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

.search-box {
  height: 30px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-box input:focus {
  box-shadow: 0 0 0 0 !important;
  border: 0 none !important;
  outline: 0 !important;
}

.search-txt {
  border: 0 none !important;
  outline: 0 none;
  background: none;
  height: 25px;
  margin-left: 3px;
  outline: none;
  font-size: 12px !important;
  width: 100%;
}

.pro-sidebar {
  width: unset !important;
  min-width: 250px;
  margin: 0;
}

.pro-sidebar-login>.pro-sidebar-inner {
  background-color: #5686bf !important;
  width: 225px !important;
  min-width: 225px !important;
}

.pro-sidebar-login .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background-color: #5686bf !important;
}

.pro-sidebar-login .pro-menu .pro-menu-item>.pro-inner-item {
  padding: 2px 5px 2px 5px !important;
}

.pro-sidebar-login .pro-menu .pro-menu-item {
  font-size: 12px !important;
}

.pro-sidebar-login .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 7px !important;
}

.div-container {
  background-color: white;
  border: 1px solid whitesmoke;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 6px;
  font-size: 12px;
  width: 100%;
  margin-top: 10px;
}

.div-container h1 {
  font-size: 14px;
  font-weight: bold;
}


.tableFixHead {
  height: calc(100vh - 340px);
}

.tableFixHead thead th {
  position: sticky;
  top: -20px;
}

.tableFixHead tfoot {
  position: sticky;
  inset-block-end: 0;
  bottom: -20px;
}


/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px 9px;
}

th {
  background: #eee;
}

/*   .scrollbar-none::-webkit-scrollbar {
    display: none;
  } */

.fundo-cinza {
  background: #eee;
}

.d-inline {
  display: inline !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-primary {
  color: #2e60aa !important;
}

.text-Futura{
  font-family: 'Futura Std', sans-serif;
}

.h1-painel {
  color: rgb(0, 0, 0);
  font-size: 80pt;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: normal;

}

.h1-painel-conversao { 
  font-family: Futura, "Trebuchet MS", Arial, sans-serif; 
  font-size: 400%; 
  font-style: normal; 
  font-variant: normal; 
  font-weight: 700; 

  color: rgb(255, 255, 255);
  text-align: center;
  margin-top: 50px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.h3-painel-efetividade { 
  font-family: Futura, "Trebuchet MS", Arial, sans-serif; 
  font-size: 280%; 
  font-style: normal; 
  font-variant: normal; 
  font-weight: 200; 
  color: rgb(255, 255, 255);
  text-align: left;
  margin-top: 65px;
  margin-right: 30px;
  margin-bottom: 30px;
}
   
.h3-painel { 
  font-family: Futura, "Trebuchet MS", Arial, sans-serif; 
  font-size: 14px; 
  font-style: normal; 
  font-variant: normal; 
  font-weight: 700; 
  line-height: 15.4px; 
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
} 
.p-painel { 
  font-family: Futura, "Trebuchet MS", Arial, sans-serif; 
  font-size: 2800%;
  font-style: normal; 
  font-variant: normal; 
  color:  rgb(79, 184, 82);
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding-bottom: -80px;
}

header {
  width: 100vw;
  height: 11vh;
}

nav {
  float: left;
}

section {
  float: right;
}

nav {
  width: 17vw;
  height: 89vh;
}

section {
  width: 83vw;
  height: 89vh;
  bottom: 0;
  margin: 0;
  margin-top: 10px;
}

.img-carousel {
  border-radius: 8px;
  font-size: 12px;
  height: 62vh;
}

.gg-info {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
  border-color: #fff;
}
.gg-info::after,
.gg-info::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    background: #fff;
    left: 22px
}
.gg-info::after {
    bottom: 4px;
    height: 30px
}
.gg-info::before {
    height: 5px;
    top: 4px
}

.btn-verde {
  background-color: #7AAE4C;
  color: white !important;
  border-radius: 20px;
}

.btn-verde:hover {
  background-color: #5e8739;
}

.btn-laranja {
  background-color: #F7941E;
  color: white !important;
  border-radius: 20px;
}

.btn-laranja:hover {
  background-color: #f88800;
}