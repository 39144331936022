.tg {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0px auto;
}

.tg td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Calibri, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 3px 5px;
    word-break: normal;
}

.tg th {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Calibri, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 3px 5px;
    word-break: normal;
}

.tg .tg-wpqz {
    background-color: #9bc2e6;
    border-color: #9bc2e6;
    text-align: center;
    vertical-align: top;
}

.tg .tg-oe15 {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: left;
    vertical-align: top;
}

.tg .tg-kaak {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: right;
    vertical-align: top;
}

.tg .tg-qo6o {
    background-color: #2f75b5;
    border-color: #2f75b5;
    color: #ffffff;
    font-family: serif !important;
    font-size: 100%;
    font-weight: bold;
    text-align: center;
    vertical-align: top;
}

.tg .tg-kdfh {
    background-color: #2f75b5;
    border-color: #2f75b5;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    vertical-align: top;
}

.tg .tg-rcw7 {
    background-color: #1f4e78;
    border-color: #1f4e78;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    vertical-align: top;
}

.tg .tg-tzeq {
    background-color: #2f75b5;
    border-color: #2f75b5;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
    vertical-align: top;
}

.tg .tg-f0cj {
    background-color: #305496;
    border-color: #305496;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    vertical-align: top;
}

.tg .tg-qwrb {
    background-color: #8ea9db;
    border-color: #8ea9db;
    text-align: left;
    vertical-align: top;
}

.tg .tg-55sn {
    background-color: #bdd7ee;
    border-color: #bdd7ee;
    text-align: right;
    vertical-align: top;
}

.tg .tg-aks7 {
    background-color: #ddebf7;
    border-color: #ddebf7;
    text-align: right;
    vertical-align: top;
}

.tg .tg-eois {
    background-color: #ddebf7;
    border-color: #ddebf7;
    text-align: right;
    vertical-align: top;
}




.tg2 {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0px auto;
}

.tg2 td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 4px 5px;
    word-break: normal;
}

.tg2 th {
    border-color: black;
    border-style: solid;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 4px 5px;
    word-break: normal;
}

.tg2 .tg-b7tv {
    border-color: #ffffff;
    text-align: left;
    vertical-align: top
}

.tg2 .tg-1ozy {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: center;
    vertical-align: top
}

.tg2 .tg-ttc1 {
    background-color: #1f4e78;
    border-color: #1f4e78;
    color: #ffffff;
    text-align: center;
    vertical-align: middle
}

.tg2 .tg-wpqz {
    background-color: #9bc2e6;
    border-color: #9bc2e6;
    text-align: center;
    vertical-align: top
}

.tg2 .tg-oe15 {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: left;
    vertical-align: top
}

.tg2 .tg-1jmn {
    background-color: #1f4e78;
    border-color: #1f4e78;
    color: #ffffff;
    font-size: 14px;
    text-align: left;
    vertical-align: middle
}

.tg2 .tg-kaak {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: left;
    vertical-align: top
}

.tg2 .tg-zv4m {
    border-color: #ffffff;
    text-align: left;
    vertical-align: top
}

.tg2 .tg-f1ex {
    background-color: #9bc2e6;
    border-color: #9bc2e6;
    text-align: left;
    vertical-align: top
}

.tg2 .tg-axoc {
    background-color: #9bc2e6;
    border-color: #9bc2e6;
    text-align: left;
    vertical-align: top
}

.tg2 .tg-ilix {
    background-color: #9bc2e6;
    border-color: #9bc2e6;
    text-align: right;
    vertical-align: top
}

.tg2 .tg-x1bh {
    background-color: #1f4e78;
    border-color: #1f4e78;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    vertical-align: middle
}

.tg2 .tg-2nun {
    border-color: #ffffff;
    color: #1f4e78;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    vertical-align: top
}

.tg2 .tg-wk8r {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: center;
    vertical-align: top
}

.tg2 .tg-trfl {
    border-color: #1f4e78;
    color: #1f4e78;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    vertical-align: top
}

.tg2 .tg-y5hu {
    background-color: #9bc2e6;
    border-color: #9bc2e6;
    text-align: center;
    vertical-align: top
}

.tg2 .tg-c1kk {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: right;
    vertical-align: top
}

.tg2 .tg-3dev {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: right;
    vertical-align: top
}

.tg2 .tg-u4lw {
    background-color: #9bc2e6;
    border-color: #9bc2e6;
    text-align: right;
    vertical-align: top
}


.tg3 {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0px auto;
}

.tg3 td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 7px 5px;
    word-break: normal;
}

.tg3 th {
    border-color: black;
    border-style: solid;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 7px 5px;
    word-break: normal;
}

.tg3 .tg-nj80 {
    background-color: #4472c4;
    border-color: #4472c4;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    vertical-align: top
}

.tg3 .tg-oe15 {
    background-color: #ffffff;
    border-color: #ffffff;
    text-align: left;
    vertical-align: top
}

.tg3 .tg-i3u6 {
    background-color: #4472c4;
    border-color: #ffffff;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    vertical-align: top
}

.tg3 .tg-fmx0 {
    background-color: #4472c4;
    border-color: #ffffff;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    vertical-align: top
}

.tg3 .tg-5a24 {
    background-color: #b4c6e7;
    border-color: #ffffff;
    font-size: 14px;
    text-align: left;
    vertical-align: top
}

.tg3 .tg-6zfa {
    background-color: #d9e1f2;
    border-color: #ffffff;
    font-size: 14px;
    text-align: left;
    vertical-align: top
}

.button {
    background-color: cornflowerblue !important;
    color: white;
    border-radius: 20px;
    height: 28px;
    width: 9rem;
  }